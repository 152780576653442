import React from "react"
import Layout from "../components/layout/layout"
import ResumeItem from "../components/Resume/ResumeItem/ResumeItem"
import SEO from "../components/seo"
import data from "../content/resume.json"
import styles from "../components/Resume/ResumeItem/resumeItem.module.css"
import TagsData from "../content/tags.json"
import styl from "./styles.module.css";

const RenderDt = (props) => {
  const techtags = props.TechnicalTags;
  const softtags = props.SoftTags;
  const hasTechTrue =techtags.includes(true);
  const hasSoftTrue =softtags.includes(true);
  let dt = data.map((key, k) => {
    if (hasTechTrue){
     return techtags.map(
        (val, idx)=>{
          if (val === true){
            if (key.tech.includes(TagsData["Technical Skills"][idx])){
              return ( <ResumeItem item={key} key={k} />)
            }
          }
        }
      )
    }
    if (hasSoftTrue){
      return softtags.map(
        (val, idx)=>{
          if (val === true){
            if (key.soft.includes(TagsData["Soft Skills"][idx])){
              return <ResumeItem item={key} key={k} />
            }
          }
        }
      )
    }
  })
  if (dt === []){
    return  data.map((key, k)=>{
      return <ResumeItem item={key} key={k} />
    })
  }
  return <div className={styl.exp}> {dt}</div> 
}

class Resume extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      TechnicalTags: [],
      SoftTags: [],
      storyMode: "Smart Resume",
    }
  }
  componentWillMount() {
    const tech = TagsData["Technical Skills"].map(() => false);
    const soft = TagsData["Soft Skills"].map(() => false);
    this.setState({ TechnicalTags: tech, SoftTags: soft })
  }
  handleTagClick = e => {
    e.preventDefault()
    const val = e.target.value
    let tags = (e.target.id === "Soft Skills") ? this.state.SoftTags : this.state.TechnicalTags;
    let restags = TagsData[e.target.id]; // get the right skills.
    let ind = restags.findIndex(key => key === val);
    tags[ind] = tags[ind] === true ? false : true;
    if (e.target.id === "Technical Skills"){
      this.setState({ TechnicalTags: tags })
    } else{
      this.setState({ SoftTags: tags })
    }
  }
  storyModeClick = e => {
    e.preventDefault()
    this.setState(
      { storyMode: e.target.value },
    )
  }
  
  renderSelectStory = () => {
    const items = ["Story Mode", "Smart Resume"];
    const renderItems = items.map(key => {
      return (
        <button
          key={key}
          onClick={this.storyModeClick}
          value={key}
          className={
            this.state.storyMode === key ? styles.selected : styles.unselected
          }
        >
          {key}
        </button>
      )
    })
    return <div className={styles.selectStory}>{renderItems}</div>
  }
  render() {
    return (
      <Layout>
        <SEO title="Resume" />
        <div className={styl.resumeContainer}>
          <h1> Nivaaz's Resume </h1>
          <RenderResumeTag TechnicalTags = {this.state.TechnicalTags} SoftTags = {this.state.SoftTags} handleTagClick = {this.handleTagClick} />
          <div className={styles.selectStory}>
          <h3 > Select resume view </h3>
          {this.renderSelectStory()}
          </div>
          {this.state.storyMode === "Story Mode" ? <RenderStory/> : ""}
          <RenderDt TechnicalTags = {this.state.TechnicalTags} SoftTags = {this.state.SoftTags} />
        </div>
      </Layout>
    )
  }
}

const RenderResumeTag = (props) => {
  const tech = TagsData["Technical Skills"].map(key => key)
  const soft = TagsData["Soft Skills"].map(key => key)

  return (
    <div className={styles.selectStory}>
      <p> Select skills to be displayed in Nivaaz's resume </p>
      <h3> Technical Skills </h3>
      <RenderTags tech = {tech} id ="Technical Skills" TechnicalTags = {props.TechnicalTags} handleTagClick = {props.handleTagClick}/>
      <h3> Soft Skills </h3>
      <RenderTags tech = {soft} id ="Soft Skills" SoftTags = {props.SoftTags} handleTagClick = {props.handleTagClick} />
    </div>
  )
}
const RenderTags = (props) => {
  let isSelected = "";
  const tgs =  props.tech.map((key, ind) => {
    if (props.id === "Technical Skills"){
       isSelected = props.TechnicalTags[ind] === true
      ? styles.selected
      : styles.unselected
    } else{
       isSelected = props.SoftTags[ind] === true
      ? styles.selected
      : styles.unselected
    }
    return (
      <button
        value={key}
        onClick={props.handleTagClick}
        id = {props.id}
        className={isSelected}
      >
        {key}
      </button>
    )
  })
  return <div className={styl.tagsBox}> {tgs} </div>
}
const RenderStory = () =>  (
      <div>
        <p>
          {" "}
          After attending the Women in Engineering Camp at UNSW in 2014, my
          bestfriends and I decided we would one day become President of the Women
          in Engineering Society at UNSW.{" "}
        </p>
        <p>
          {" "}
          So then our mission began, as I entered into university as a young
          little electrical engineer in the making.
        </p>
        <p>
          {" "}
          To make this happen I needed to make connection and join as many
          socieities as possible to get hands on experience for the role.
        </p>
        <p>
          {" "}
          So I started off in the marketing team for the UNSW Sikh Society, and
          then worked my way up to Head of Marketing the following year.
        </p>
        <p>
          {" "}
          Next I got super involved in the electrical engineeing society - and
          while I didn't get selected as first year representative (because I
          couldn't chug a drink fast enough). I got asked to join TWEET (The Women
          in Electrical Engineering & Telecommunications)
        </p>
        <p>
          {" "}
          So the following year I become Industry Liasion for TWEET, where I had
          the opportunity of a life time. I had the chance to lead the
          organisation of our biggest Industry Night yet, which had a 300%
          increase in attendance.{" "}
        </p>
        <p>
          {" "}
          Of course, this is also when my coffee addiction came into full force.
        </p>
  
        <p>
          {" "}
          I wasn't coping very well with uni, so I wanted to find another way to
          make myself employable. So I set myself the mission of finding
          opportunities that would help me upskill to gain an advantage over other
          application for roles.
        </p>
        <p> And then I got into the STEM Leaders program over the summer. </p>
        <p>
          {" "}
          After completeing the program sucessfully and winning the best
          presentation, I landed a role as a Design Intern, at the EduTech
          startup, Doppler.
        </p>
  
        <p>
          {" "}
          From here I landed a role tutoring at an Australian Tutoring company,
          Art of Smart.
        </p>
        <p>
          {" "}
          6 months into this role, the opportunity to become a team leader popped
          up, and while I didn't thik I had the experience or skills for the role.
          I got selected, and turned out to be one of the best team leaders they
          had in the program.
        </p>
  
        <p>
          {" "}
          Now I wanted to really learn to program, but without a project in mind,
          I had nothing to keep me accountable for my progress. So I asked the CEO
          of my tutoring company if he knew anyone that would be keen to hire me
          as an intern and help me learn to code.{" "}
        </p>
        <p>
          {" "}
          And then he turned to me and said, "I might actually have something for
          you"
        </p>
        <p>
          {" "}
          And so began my journey to become a self taught software engineer.{" "}
        </p>
        <p>
          {" "}
          At the end of that year I won the company leadership award that year for
          all of my amazing contributions to the company.
        </p>
  
        <p>
          {" "}
          I was so excited about the progress that I had made, that I decided to
          share it all on my linkedin, and funnily enough, a friend of mine saw I
          could code and asked me to join their startup.
        </p>
        <p>
          {" "}
          Now I was so hesitant, because the last startup I had worked at didn't
          end well, and I did want to make an income. But it all sounded like it
          was on track, so I joined as a frontend software engineer (where I
          learnt to code in jQuery for the first time)
        </p>
        <p>
          {" "}
          The code was a nightmare, but I made it through, althogh I don't think
          the company did.
        </p>
  
        <p>
          {" "}
          The next year I joined TWEET as the President, and met a Trading Company
          at the Annual Industry Night Event. From here came an unexpected
          opportunity to design their internship program, and do an intership with
          them! And so I did.
        </p>
        <p>
          {" "}
          Then just as I was sure that I would never get an electrical engineering
          job in my lifetime, I got an unexpected call from Aurecon.
        </p>
        <p>
          {" "}
          I'll be honest with you, I thought the recuirter was calling to tell me
          I wasn't making it through to the next round, but instead it was for an
          interview!
        </p>
        <p>
          {" "}
          After passing the interview, which was actually a lot of fun, with
          flying colours, I landed my first engineering role.
        </p>
  
        <p>
          {" "}
          After 6 months in the role, I realised that electrical engineering
          consultancy wasn't for me.
        </p>
        <p>
          {" "}
          So I set out for my next venture to become an FPGA Engineer at a trading
          company.
        </p>
  
        <p>
          {" "}
          Just as my heart locked onto the dream of becoming an FPGA Engineer or
          trader at a trading company. A really unexpected opportunity came my
          way.
        </p>
        <p>
          {" "}
          As I began talking to a senior softwar engineer from canva who had
          inspired me so much I had goosebumps from her presentation, she
          recommended that I apply for a role at Canva.
        </p>
        <p>
          {" "}
          And so I did, even though I had no faith in myself to make it through a
          single round.
        </p>
        <p>
          {" "}
          Then I landed my first role as a front end softwar engineering intern,
          as a self taught software developer. The imposter syndrome was too real.
        </p>
  
        <p>
          {" "}
          And then reality hit, I had a little too much fun and took work a little
          to easily, and didn't get an offer for a graduate position.{" "}
        </p>
        <p>
          {" "}
          As my heart shattered to pieces, they told me "Work on your programming
          skills and come back in 6 months".
        </p>
  
        <p>
          {" "}
          So now I'm sitting here, writing this story about all my experiences, as
          a part of my "smart resume", which is one of the many projects I have
          undertaken to improve my software engineering skills to make sure I get
          the job in 6 months.
        </p>
        <p> I'm not leaving my future up to chance again. </p>
      </div>
    )


export default Resume
